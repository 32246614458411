<template>
  <div class="pre-feedback">
    <PostList
      :posts="posts"
      :stories="stories"
      :isShowCloseIcon="false"
      :currentCustomer="getCurrentCustomer"
      :postType="'pre-feedback'"
      :isShowComment="false"
      :postListType="'narrow'"
      isShowEditIcon
      @approveAction="addToApproveHandler($event)"
      @postDeletedSuccessfully="deletePostHandler"
      isShowTopActions
      isShowDateCreation
      isShowDeletedIcon
      isCanEditPublishDate
      isShowPublishTime
      isShowAllowMovePostIcon
      headerTitle="Created"
      @updatePostsList="updatePost"
      :checkedList="getFeedbackApprovedList"
      @updateSchedule="updateScheduleHandler"
      isSortedByScheduledForStr
      isCanSelectAllPosts
      isCanSelectAllStories
      isShowStoryTitle
      @selectOrUnselectAllPosts="selectOrUnselectAllPostsHandler"
      @selectOrUnselectAllStories="selectOrUnselectAllStoriesHandler"
      isCanShowEditedLabel
      isCanShowBackupData
      isShowDeleteSchedulingIcon
    />
    <ModalDialog
      :isShow="dialog.isShow"
      :title="dialog.title"
      :icon="require('@/assets/img/icons/success-icon.svg')"
      :footerButtons="dialog.footerButtons"
      @success="updatePost()"
      @close="dialog.isShow = false"
    >
      <template v-slot:description>
        All marked posts have been sent in the message from
        <router-link :to="{ name: 'dashboard' }"
          >Communication Widget</router-link
        >.
      </template>
    </ModalDialog>
    <ModalDialog
      :isShow="wrongDialog.isShow"
      :title="wrongDialog.title"
      :icon="require('@/assets/img/icons/warning-icon.svg')"
      :footerButtons="wrongDialog.footerButtons"
      @close="wrongDialog.isShow = false"
    />
    <Loader v-if="isLoading" class="loader" />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import PostList from "@/components/createPost/PostList";
import ModalDialog from "@/components/global/ModalDialog";
import Loader from "@/components/global/Loader";

export default {
  name: "PreFeedback",
  data: () => ({
    posts: [],
    stories: [],
    dialog: {
      isShow: false,
      title: "Posts have been shared",
      footerButtons: [{ name: "Ok", emitName: "success", btnClass: "violet" }],
    },
    wrongDialog: {
      isShow: false,
      type: "error",
      title: "Something went wrong",
      footerButtons: [{ name: "Close", emitName: "close", btnClass: "violet" }],
    },
    isLoading: false,
  }),
  props: {
    isShare: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    PostList,
    ModalDialog,
    Loader,
  },
  watch: {
    isShare() {
      this.shareWithSmbHandler();
    },
    getFeedbackApprovedList() {
      this.$emit("feedbackApprovedList", this.getFeedbackApprovedList);
    },
  },
  async created() {
    await this.getPosts();
  },
  computed: {
    ...mapGetters("createPost", ["getFeedbackApprovedList"]),
    ...mapGetters("clientSelection", ["getCurrentCustomer"]),
    ...mapGetters("auth", ["getCurrentUser"]),
  },
  methods: {
    ...mapActions("createPost", ["fetchPosts", "exportPosts"]),
    ...mapMutations("createPost", [
      "setToFeedbackApprovedList",
      "deleteFromFeedbackApprovedList",
      "refreshStateByName",
    ]),
    async getPosts() {
      const data = await this.fetchPosts({
        routeName: "pre-approved",
        customerId: this.getCurrentCustomer.id,
        limit: 100,
      });
      this.posts = [];
      this.stories = [];
      data.forEach((item) => {
        item.type === "post" ? this.posts.push(item) : this.stories.push(item);
      });
    },
    async shareWithSmbHandler() {
      this.isLoading = true;
      try {
        await this.exportPosts({
          customerId: this.getCurrentCustomer.id,
          postsIdList: this.getFeedbackApprovedList,
          smmId: this.getCurrentUser.id,
          exportedTo: "customer",
        });
        this.isLoading = false;
        this.dialog.isShow = true;
        this.refreshStateByName({
          stateName: "preFeedbackApprovedList",
          value: [],
        });
        await this.getPosts();
      } catch (error) {
        this.isLoading = false;
        this.wrongDialog.isShow = true;
      }
    },
    addToApproveHandler(params) {
      if (params.approveFlag) {
        this.setToFeedbackApprovedList(params.postId);
      } else {
        this.deleteFromFeedbackApprovedList(params.postId);
      }
    },
    updatePost() {
      this.getPosts();
      this.dialog.isShow = false;
      this.$emit("updateAmountPost");
    },
    deletePostHandler() {
      this.getPosts();
      this.$emit("updateAmountPost");
    },
    updateScheduleHandler(postData) {
      let searchPost =
        this.posts.find((post) => post.id === postData.postId) ||
        this.stories.find((post) => post.id === postData.postId);
      searchPost
        ? (searchPost.scheduledForStr = postData.scheduledForStr)
        : null;
    },
    selectOrUnselectAllPostsHandler(isNeedToSelectAllPosts) {
      this.posts.forEach((post) => {
        this.deleteFromFeedbackApprovedList(post.id);
      });

      if (isNeedToSelectAllPosts) {
        this.posts.forEach((post) => this.setToFeedbackApprovedList(post.id));
      }
    },
    selectOrUnselectAllStoriesHandler(isNeedToSelectAllStories) {
      this.stories.forEach((post) => {
        this.deleteFromFeedbackApprovedList(post.id);
      });

      if (isNeedToSelectAllStories) {
        this.stories.forEach((post) => this.setToFeedbackApprovedList(post.id));
      }
    },
  },
  destroyed() {
    this.refreshStateByName({
      stateName: "preFeedbackApprovedList",
      value: [],
    });
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/vars";
@import "@/assets/styles/mixins";
.pre-feedback {
  .loader {
    ::v-deep {
      position: fixed;
    }
  }
}
</style>
